import React from "react";
import instagramImage from '../images/instagram.png';
import facebookImage from '../images/facebook.png';
import skImage from '../images/skflag.png';
import deImage from '../images/deflag.png';
import { useTranslation } from "react-i18next";

function Contact(){
    const {t} = useTranslation("common");
    return(
        <div id="contact" className=" w-full h-full bg-yellow-400 flex justify-center">
        <ul className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 p-10 text-2xl text-black w-full' >
                <li className="flex flex-col items-center">
                    <h1 className="mb-4 font-bold"> {t('Sociálne siete')} </h1>
                    <div className="flex flex-col gap-2 items-center">
                        <a href="https://www.instagram.com/vnbbau/">
                            <img className=" w-14 h-14 object-cover" src={instagramImage}/>
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=61555669226019">
                            <img className=" w-12 h-12 object-cover" src={facebookImage}/>
                        </a>
                    </div>
                </li>
                <li className="flex flex-col items-center">
                    <h1 className="mb-4 font-bold"> {t('Adresa')} </h1>
                    <div className="flex flex-col gap-2 items-center">
                        <span>Hurbanova 666/18</span>
                        <span>085 01 Bardejov</span>
                        <span>{`2. ${t('poschodie')}`}</span>
                    </div>
                </li>
                <li className="flex flex-col items-center">
                    <h1 className="mb-4 font-bold"> E-mail </h1>
                    <a className=" hover:underline" href="mailto:vnbbau@gmail.com">vnbbau@gmail.com</a>
                    <h1 className="text-lg mb-4 font-bold font-size"> {t('Ochrana osobných údajov(GDPR)')} </h1>
                    <div className="flex flex-col items-center">
                        <a className="hover:underline text-blue-600 text-lg" href="/documents/gdprDE.pdf" target="_blank" rel="noopener noreferrer">
                            {t('ochrana osobných údajov')} DE
                        </a>
                        <a className="hover:underline text-blue-600 text-lg" href="/documents/gdprSK.pdf" target="_blank" rel="noopener noreferrer">
                        {t('ochrana osobných údajov')} SK
                        </a>
                    </div>
                    

                </li>
                <li className="flex flex-col items-center">
                    <h1 className="mb-4 font-bold"> {t('Telefónne čísla')} </h1>
                    <div className="flex gap-3 items-center">
                    <img className="w-6 h-6 object-cover" src={skImage}/>
                        <a className=" hover:underline" href="tel:+421 948 950 907">+421 948 950 907</a>
                    </div>
                    <div className="flex gap-3 items-center">
                    <img className="w-6 h-6 object-cover" src={skImage}/>
                        <a className=" hover:underline" href="tel:+421 911 737 376">+421 911 737 376</a>
                    </div>
                    <div className="flex gap-3 items-center">
                    <img className="w-6 h-6 object-cover" src={deImage}/>
                        <a className=" hover:underline" href="tel:+421 948 571 336">+421 948 571 336</a>
                    </div>
                </li>  
        </ul>
        </div>
    )
}
export default Contact